<template>
  <div class="font-body AsomLogin">
    <div class="AsomLogin__Wrapper">
      <div class="AsomLogin__Image text-white text-center"></div>
      <div class="AsomLogin__Fields space-y-8">
        <div class="h-30">
          <picture>
            <source srcset="../../assets/images/asoms-logo.webp" type="image/webp" />
            <img
              class="AsomLogin__Logo"
              src="../../assets/images/asoms-logo.png"
              alt="ASOMS"
            />
          </picture>
          
        </div>

        <div class="space-y-3">
          <asom-alert
            class="col-span-2"
            v-if="error"
            variant="error"
            :error-message="error"
          />
          <asom-form-field
            :label="$t('screens.auth.Login.form.userName.label')"
          >
            <asom-input-text
              :placeholder="$t('screens.auth.Login.form.userName.placeholder')"
              v-model="userName"
              :state="inputStates('userName')"
            />
          </asom-form-field>
          <asom-form-field
            :label="$t('screens.auth.Login.form.password.label')"
          >
            <asom-input-text
              :placeholder="$t('screens.auth.Login.form.password.placeholder')"
              type="password"
              v-model="password"
              :state="inputStates('password')"
            />
          </asom-form-field>
          <div class="mt-10">
            <asom-button
              size="full"
              @click="onSubmit"
              :text="$t('screens.auth.Login.form.login')"
              :loading="isLoggingIn"
            />
          </div>
          <asom-button
            variant="link"
            size="full"
            text="Forgot Password"
            @click="forgotPassword"
          />
          <asom-button
            variant="link"
            size="full"
            :text="$t('screens.auth.Login.test.accounts')"
            @click="$router.push({ path: 'test-login' })"
          />
        </div>
      </div>
    </div>
    <span class="absolute bottom-1 right-2 text-sm text-white">version v.{{appVersion}}</span>
  </div>
</template>

<script>
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import appInfo from "@/../package.json"
import { AUTH_FORGOT_PASSWORD } from "../../constants/apis";

export default {
  mixins: [inputStates],
  data() {
    return {
      userName: "",
      password: "",
      isLoggingIn: false,
      error: "",
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      userName: { required },
      password: { required },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: `auth/isAuthenticated`,
    }),
    appVersion() {
      return get(appInfo, 'version')
    },
    forgotPasswordUrl() {
      return AUTH_FORGOT_PASSWORD;
    }
  },
  mounted() {
    if (this.v$) {
      this.v$.$reset();
    }
    if (get(this.$route, "params.forceLogout"))
      this.error = "Your session has ended. Please login again.";
  },
  methods: {
    async onSubmit() {
      if (this.isLoggingIn) return;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isLoggingIn = true;
        const loginResult = await this.$store.dispatch("login", {
          userName: this.userName,
          password: this.password,
          accessKey: process.env.VUE_APP_ACCESS_KEY,
        });
        this.isLoggingIn = false;
        if (this.isLoggedIn) {
          this.$router.push({ name: "Home" });
        } else {
          this.error = loginResult.payload;
        }
      } else {
        this.error = "Username and Password are required.";
      }
    },
    forgotPassword() {
      window.open(this.forgotPasswordUrl, '_blank');
    }
  },
};
</script>

<style scoped>
.AsomLogin__Wrapper {
  margin-left: 20px;
  margin-right: 20px;
  z-index: 1;
  -webkit-box-shadow: 0px 10px 32px 5px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px 10px 32px 5px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 10px 32px 5px rgba(0, 0, 0, 0.69);
  @apply flex bg-white relative rounded-xl overflow-hidden;
}
.AsomLogin__Fields,
.AsomLogin__Image {
  @apply flex-1;
}
.AsomLogin__Greeting {
  font-size: 1.75em;
  line-height: 1;
  @apply font-semibold text-gray-500 text-2xl;
}
.AsomLogin__Fields {
  max-width: 25rem;
  @apply p-12;
}
.AsomLogin {
  background-image: url("~@/assets/images/smrt_bg.webp"), url("~@/assets/images/smrt_bg.png");
  @apply min-h-screen bg-cover flex items-center justify-center text-left;
}
.AsomLogin__Image {
  background-image: url("~@/assets/images/asmos-login-img.webp"), url("~@/assets/images/asmos-login-img.jpg");
  @apply bg-cover text-center flex justify-center bg-center hidden lg:block;
}
.AsomLogin::after {
  content: "";
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: 0.7;
  z-index: 0;
  @apply absolute bg-gray-900;
}
.AsomLogin__Logo {
  height: 6.5em;
  @apply mx-auto;
}
.AsomLogin_Button {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
.status-icon {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 20px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 1);
}
.color-online {
  box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
    0px 0px 10px 2px rgba(0, 255, 135, 1);
}
.color-offline {
  box-shadow: inset 0px 0px 10px 2px rgba(255, 0, 0, 0.5),
    0px 0px 10px 2px rgb(255, 0, 0);
}
.color-checking {
  box-shadow: inset 0px 0px 10px 2px rgba(255, 208, 0, 0.5),
    0px 0px 10px 2px rgb(255, 94, 0);
}


@media (min-width: 1024px) {
  .AsomLogin__Wrapper {
    width: 50rem;
    margin: 0;
  }
}
</style>
